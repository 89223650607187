@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap");

@font-face {
  font-family: "Hellix"; /*Can be any text*/
  src: local("Hellix-Regular"),
    url("./fonts/Hellix-Regular.ttf") format("truetype");
}

body {
  font-family: "Hellix", sans-serif;
}

p {
  margin: 0;
  padding: 0;
}
span {
  margin: 0;
  padding: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.buttonBg {
  background: linear-gradient(135deg, #fd9b14 0%, #e90880 100%);
}

.sidebarBorder {
  background: linear-gradient(
    180deg,
    rgba(39, 30, 39, 0) 0%,
    #271e27 53.61%,
    rgba(39, 30, 39, 0) 103.1%
  );
}
::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* scroll bar css start */
.noScrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.noScrollbar {
  -ms-overflow-style: none; /* for Internet Explorer and Edge */
  scrollbar-width: none; /* for Firefox */
}
/* scroll bar css end */  

/* slider css start */
.slider {
  width: 200px !important;
  margin: 0 20px;
  overflow: "hidden";
  padding: 2rem 0;
}

.slider img {
  width: 200px;
  height: 230px;
  object-fit: cover;
  border-radius: 10px;
}

.react-multi-carousel-track li {
  width: 220px !important;
}

.react-multi-carousel-track .react-multi-carousel-dot-list li {
  display: none !important;
}
.react-multiple-carousel__arrow--right {
  display: none;
}
.react-multiple-carousel__arrow--left {
  display: none;
}
.custom-dot-list-style li {
  display: none !important;
}
/* slider css end */

/* checkbox css start */
/* input[type="checkbox"] + label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid red;
  border-radius: 0.2em;
  display: inline-block;
  width: 1em;
  height: 1em;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}

input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

input[type="checkbox"]:checked + label:before {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
} */
/* checkbox css end */

/* mui data grid css start */
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon {
  visibility: invisible !important;
}

.css-3eek4p-MuiDataGrid-main {
  padding: 18px !important;
}
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border: none !important ;
}
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-container--top [role="row"],
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"] {
  border: none !important;
  border-radius: 10px !important;
  padding: 8px 0px !important;
  background-color: #131313 !important;
  box-shadow: 0px 0px 32px 0px #7c6f6f0a inset !important;
  color: #838383;
  font-size: 16px;
  font-weight: 500;
  font-family: "Hellix", sans-serif !important;
}
/* mui data grid css end */

#slider {
  transition: 0.4s;
}

#slider::before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 18px;
  top: 2px;
  transition: 0.4s;
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
}

input:checked + #slider {
  background: green;
}

input:checked + #slider:before {
  transform: translateX(-16px);
}

input[type='number'] {
  @apply [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none;
}